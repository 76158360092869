import { logEvent } from "firebase/analytics";
import { getAnalyticsInstance } from "@/firebaseConfig";

type GoogleAnalyticsEvent =
  | "view_item"
  | "add_to_cart"
  | "begin_checkout"
  | "add_to_wishlist"
  | "purchase"
  | "login"
  | "search"
  | "add_shipping_info"
  | "view_cart"
  | "remove_from_cart"
  | "add_payment_info";

interface EventParams {
  value?: number;
  currency?: string;
  items?: Array;
  transaction_id?: string;
  method?: string;
  search_term?: string;
}

export function useGoogleAnalytics() {
  function analyticsEvent(eventName: GoogleAnalyticsEvent, params: EventParams = {}): void {
    const analytics = getAnalyticsInstance();
    if (analytics) {
      try {
        logEvent(analytics, eventName, params);
        //console.log(`Evento ${eventName} registrado con éxito`, params);
      } catch (error) {
        console.error(`Error al registrar el evento ${eventName}:`, error);
      }
    } else {
      console.warn("Google Analytics no está inicializado.");
    }
  }

  return { analyticsEvent };
}
