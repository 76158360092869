import { computed, reactive } from "vue"
import { createResource } from "frappe-ui"
import { userResource } from "./user"
import router from "@/router"
import analyticsService from "./analytics"
import { useFacebookPixel } from '@/composables/useFacebookPixel';
import { useGoogleAnalytics } from '@/composables/useGoogleAnalytics';

const { trackEvent } = useFacebookPixel();
const { analyticsEvent } = useGoogleAnalytics();

export function sessionUser() {
	const user_id = localStorage.getItem('user_id')
	user_id ? analyticsService.setUserId(user_id.toString()) : analyticsService.setUserId('Guest')
	return user_id
}

export const session: any = reactive({
	login: createResource({
		url: 'happygift.auth.login',
		makeParams({ email, password }) {
			return {
				usr: email,
				pwd: password,
			}
		},
		async onSuccess(data: any) {
			//console.log('success login')
			if (data.success_key === 1) {
				session.token = "token " + data.api_key + ":" + data.api_secret
				localStorage.setItem('user_id',data.email)
				localStorage.setItem('token',session.token)
				session.user = sessionUser()
				userResource.reload()
				await userResource.promise
			}
			analyticsEvent('login', {
				method: 'email y password',
			});
			//por que estas dos cosas se hacen afuera del success key?
			session.login.reset()
			router.replace("/admin/home")
			// if(userResource?.data?.onboarding_status !== 'Completed'){
			// 	router.replace("/admin/onboarding")
			// } else {
			// 	router.replace("/admin/home")
			// }
		},
		onError(error:any){
			//console.log('error login: ', error)
			localStorage.removeItem('user_id')
			localStorage.removeItem('token')
			session.user = sessionUser()
			session.token = ''
			userResource.reset()
			router.replace({ name: "login" })
		}
	}),
	loginWithSocial: createResource({
		url: 'happygift.auth.login_with_social_new',
		makeParams({provider, user, token}) {
			return {
				provider: provider,
				user: user,
				token: token
			}
		},
		async onSuccess(data: any) {
			//console.log('success google login -> ', data)
			if (data.success_key === 1) {
				session.token = "token " + data.api_key + ":" + data.api_secret
				localStorage.setItem('user_id',data.email)
				localStorage.setItem('token',session.token)
				session.user = sessionUser()
			}
			//por que estas dos cosas se hacen afuera del success key?
			session.loginWithSocial.reset()

			if(data.is_registered && data.is_registered != 0 && data.is_registered != ''){
				userResource.reload()
				await userResource.promise
				analyticsEvent('login', {
					method: 'loginWithSocial',
				});
				router.replace("/admin/home")
			} else {
				userResource.reload()
				await userResource.promise
				router.replace("/admin/social_login_register")
			}

			//router.replace("/admin/home")
		},
		onError(error:any){
			//console.log('error social login: ', error)
			localStorage.removeItem('user_id')
			localStorage.removeItem('token')
			session.user = sessionUser()
			session.token = ''
			userResource.reset()
			router.replace({ name: "login" })
		}
	}),
	signup: createResource({
        url: 'happygift.api.custom_sign_up' ,
        makeParams({ email, password, firstname, lastname, mobile, birth_date }) {
			return {
				usr: email,
				pwd: password,
				firstname: firstname,
				lastname: lastname,
				mobile: mobile,
				birth_date: birth_date
			}
		},
        onSuccess(data: any){ //de adonde saca esta data si el return no tiene nada
			if (Array.isArray(data) && data[0] == 0){
				session.signup.error = data[1]
				localStorage.removeItem('user_id')
				localStorage.removeItem('token')
				session.user = sessionUser()
				session.token = ''
				userResource.reset()
				router.replace({ name: "login" })
				return
			} else {
				session.login.submit({ email: session.signup.params.usr, password: session.signup.params.pwd });
			}
			session.signup.reset();
        },
        onError(error: any){
			//console.log('Error new account: ', error);
			session.signup.error = error.message;
			localStorage.removeItem('user_id')
			localStorage.removeItem('token')
			session.user = sessionUser()
			session.token = ''
			userResource.reset()
			// router.replace({ name: "login" })
        }
    }),
	socialRegister: createResource({
		url: 'happygift.api.social_registration',
		makeParams({ firstname, lastname, mobile, birth_date }) {
			return {
				firstname: firstname,
				lastname: lastname,
				mobile: mobile,
				birth_date: birth_date
			}
		},
		async onSuccess(data){
			// console.log('Exito en el registro de usuario de social login', data)
			userResource.reload()
			await userResource.promise
			trackEvent('CompleteRegistration', {
				value: 1.5,
				currency: 'CLP',
			});
			router.replace("/admin/home")
		},
		onError(error){
			//console.log('Error en registro de usuario de social login', error)
			//que hago con este error?
		}
	}),
	logout: createResource({
		url: "logout",
		onSuccess() {
			localStorage.removeItem('user_id')
			localStorage.removeItem('token')
			session.user = sessionUser()
			session.token = ''
			userResource.reset()
			//console.log('user logout', userResource)
			router.replace({ name: "login" })
			window.location.reload()
		},
	}),
	user: sessionUser(),
	isLoggedIn: computed(() => !!session.user),
	token: ''
})

/* interface LoginCredentials {
	email: string
	password: string
}

export interface Session {
	login: {
		loading: boolean
		submit: (credentials: LoginCredentials) => void
		reset: () => void
	}
	logout: { loading: boolean; submit: () => void; reset: () => void }
	user: null | string
	isLoggedIn: boolean
} */

export const reset =  reactive({
	resetEmail: createResource({
		url: 'happygift.user_custom.reset_password' ,
		method: 'POST',
		makeParams({email}) {
			return {
				user: email
			}
		},
		onSuccess(data) {
			//console.log('email send to reset',data)
		},
		onError(error) {
			//console.log('error to reset',error)
		}  
	})
})

export const updatePassword =  reactive({
	updateEmail: createResource({
		url: 'frappe.core.doctype.user.user.update_password' ,
		method: 'PUT',
		makeParams({password_ ,key_ }) {
			return {
				key: key_,
				new_password: password_,
				logout_all_sessions:1
			}
		},
		onSuccess(data) {
			//console.log('update password',data)
		},
		onError(error) {
			//error
		}  
	})
})