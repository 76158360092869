// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCH4Nsznk8s_IlxnjcKjNJV1KNDMv3xiHg",
  authDomain: "auth.happygiftapp.com",
  databaseURL: "https://happygift-cb045.firebaseio.com",
  projectId: "happygift-cb045",
  storageBucket: "happygift-cb045.appspot.com",
  messagingSenderId: "391482925150",
  appId: "1:391482925150:web:58b4816e74018286b3d4cf",
  measurementId: "G-3ND6M4N7RC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let analytics // Declarar como indefinido inicialmente

// Función para inicializar y obtener Analytics
function getAnalyticsInstance() {
  if (!analytics && typeof window !== 'undefined') {
    try {
      analytics = getAnalytics(app);
    } catch (error) {
      console.error("Error inicializando Google Analytics:", error);
    }
  }
  return analytics;
}

export { app, getAnalyticsInstance, logEvent };
// Optionally, export the initialized services


// const db = firebaseApp.firestore();
// const auth = firebaseApp.auth();

// export { app };
