import { Capacitor } from '@capacitor/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';

type FacebookPixelEvent = 
  | 'PageView'
  | 'ViewContent'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'AddToWishlist'
  | 'Purchase'
  | 'CompleteRegistration'
  | 'AddPaymentInfo'
  | 'Search';

interface EventParams {
  content_ids?: string[]; // IDs de los productos involucrados
  content_type?:string; // Puede ser product o product_group
  content_name?: string;  // Nombre del producto o categoría
  content_category?: string; // Categoría de los productos
  value?: number;         // Valor asociado al evento
  currency?: string;      // Moneda
  num_items?: number;     // Número de artículos
  search_string?: string;
}

export function useFacebookPixel() {
  /**
   * Rastrear un evento personalizado en la web usando Facebook Pixel.
   */
  function trackEventWeb(eventName: FacebookPixelEvent, params: EventParams = {}): void {
    if (typeof window.fbq === 'function') {
      window.fbq('track', eventName, params);
    } else {
      console.error(`Facebook Pixel no está disponible para el evento ${eventName}`);
    }
  }

  /**
   * Rastrear un evento en aplicaciones móviles usando Facebook SDK.
   */
  async function trackEventApp(eventName: FacebookPixelEvent, params: EventParams = {}): Promise<void> {
    try {
      await FacebookLogin.logEvent({
        name: eventName,
        ...(params as any),
      });
    } catch (error) {
      console.error(`Error al enviar el evento ${eventName}:`, error);
    }
  }

  /**
   * Detecta la plataforma y llama a la función correspondiente.
   */
  async function trackEvent(eventName: FacebookPixelEvent, params: EventParams = {}): Promise<void> {
    if (Capacitor.getPlatform() === 'web') {
      trackEventWeb(eventName, params);
    } else {
      await trackEventApp(eventName, params);
    }
  }

  return { trackEvent };
}
